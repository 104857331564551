<template>
  <v-card class="ma-0 ma-sm-4 d-flex flex-grow-1 flex-column">
    <div class="flex-grow-1 flex-sm-grow-0 forms-content overflow-auto">
      <h3 class="subtitle-1 mb-1 px-4 pt-4">Filtros</h3>
      <v-divider/>
      <section class="pa-4">
        <v-form ref="form">
          <relatorio-aniversariantes-form v-model="filters"/>
        </v-form>
      </section>
      <section>
        <h3 class="subtitle-1 mb-1 px-4">Ordenação</h3>
        <v-divider/>
        <order-input v-model="orders"/>
      </section>
    </div>
    <v-divider/>
    <section class="pa-2">
      <v-btn block color="primary" @click="openExportMenu">
        Exportar
      </v-btn>
    </section>
        <responsive-menu ref="menu"
                         @click:item="exportReport"
                         title="Exportar" :opcoes="menuOptions"/>

  </v-card>


</template>

<script>
import {downloadReport, findAvailableOrders, ReportAniversariantesSearch, ReportType} from "../../api/relatorios";
import RelatorioAniversariantesForm from "../../components/shared/forms/relatorios/RelatorioAniversariantesForm";
import ResponsiveMenu from "../../components/app/ResponsiveMenu";
import OrderInput from "../../components/shared/common/OrderInput";

export default {
  name: "index",
  components: {OrderInput, ResponsiveMenu, RelatorioAniversariantesForm},
  data () {
    return {
      tabSection: 0,
      menuOptions: [
        {
          action: 'pdf',
          text: 'Exportar como PDF'
        },
        {
          action: 'csv',
          text: 'Exportar como CSV'
        }
      ],
      filters: {},
      orders: [],
      report: ReportType.REL_ANIVERSARIANTES
    }
  },
  methods: {
    openExportMenu(ev) {
      if(!this.$refs.form.validate()) return;
      this.$refs.menu.open(ev);
    },
    async exportReport(v) {
      await downloadReport(this.report, {
        ...this.filters,
        formatoSaida: v,
        ordenacao: this.orders.map(order => order.field)
      });
    },
    async refreshOrderOPtions() {
      this.orders = await findAvailableOrders(this.report);
    }
  },
  mounted() {
    this.refreshOrderOPtions();
  }
}
</script>

<style scoped lang="scss">

.forms-content {
  max-height: calc(100vh - 115px);
}

</style>